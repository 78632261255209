import React, { Component } from "react"
import Konami from "react-konami-code"
import anime from "animejs/lib/anime.es.js"

// components
import SEO from "components/SEO"
import HomeNavigation from "components/HomeNavigation"

import Lottie from "react-lottie"
import * as animationData from "components/Navigation/animation.json"

class HomeIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showNavigation: false,
    }
  }

  setCookie() {
    if (typeof window !== `undefined`) {
      if (window.localStorage.getItem("showLogo") === null) {
        window.localStorage.setItem("showLogo", true)
        this.setState({ showNavigation: true })
      }
    }
  }

  componentDidMount() {
    this.setCookie()
    if (typeof window !== `undefined`) {
      if (window.localStorage.getItem("showLogo") === "false") {
        this.setState({ showNavigation: true })
      } else {
        this.setState({ showNavigation: false })
      }
    }
      // dit is de intro logo
    const animationLogoOut = anime({
      targets: this.refs.logoIntro.querySelector("div"),
      translateY: ["0%", "-400%"],
      duration: 800,
       easing: "easeInCubic",
      //easing: "easeInOutElastic(1, .9)",
        autoplay: false,
      })
      // timeout wanneer navigatie moet showen
    setTimeout(() => {
      window.localStorage.setItem("showLogo", false)
      this.setState({
        showNavigation: true,
      })
    }, 2550)
        //timeout voor wanneer de animatie moet afspelen (animation.json van het logo)
    setTimeout(() => {
      animationLogoOut.play()
    }, 2000)
  }
  getImagePosition = () => {
    const positions = [0, 280, 140]
    if (typeof window !== `undefined`) {
      if (window.innerWidth >= 1024) {
        return positions[Math.floor(Math.random() * positions.length)]
      } else {
        return 0
      }
    }
  }

  // TO ACTIVATE:
  // ↑ ↑ ↓ ↓ ← → ← → B A
  easterEgg = () => {
    if (typeof document !== `undefined`) {
      const s = document.createElement("script")
      s.type = "text/javascript"
      document.body.appendChild(s)
      s.src =
        "https://rawcdn.githack.com/erkie/erkie.github.com/4a7222bbba100b50b89cea42ce070bb6258fb1d9/asteroids.min.js"
    }
  }
  render() {
    const defaultOptions = {
      animationData: animationData.default,
      loop: false,
      autoplay: true,
    }
    const { showNavigation } = this.state
    return (
      <>
        <SEO seo={{ title: "Jouw wordt-kunstenaars. Wij gidsen organisaties naar een betere toekomst." }} />
        {showNavigation === false && (
          <div className="logo-intro" ref="logoIntro">
            <Lottie
              options={defaultOptions}
              height={400}
              width={400}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: () => {
                    // console.log("complete")
                    if (typeof window !== `undefined`) {
                      // window.localStorage.setItem("showLogo", false)
                      // this.setState({
                      //   showNavigation: true,
                      // })
                    }
                  },
                },
              ]}
            />
          </div>
        )}
        {showNavigation === true && <HomeNavigation />}
        <span className="background"></span>
        <Konami action={this.easterEgg} />
      </>
    )
  }
}

export default HomeIndex
