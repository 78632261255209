import React, { Component } from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import anime from "animejs/lib/anime.es.js"
import Scrollbar from "smooth-scrollbar"
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll"
import Lottie from "react-lottie"
import * as animationData from "./animation.json"

import styles from "./navigation.module.scss"
import NavigationItem from "components/NavigationItem"
import Footer from "components/Footer"

import LogoKeytoe from "assets/logo/Keytoe_logo.svg"
import LogoKeytoeSmall from "assets/logo/Keytoe_logo_wit_klein.svg"

import LogoFull from "assets/logo/keytoe-logo.svg"
import Logo from "assets/logo/keytoe-k-logo.svg"

class HomeNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: null,
    }
  }
  componentDidMount() {
    this.calculateSize()
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.calculateSize)
    }

    // document.body.style.background = "#256eff"
      // logo keytoe animatie
    setTimeout(() => {
      anime({
        targets: `.${styles.logo}`,
        duration: 600,
        translateY: [-114, 0],
        opacity: [0, 1],
        delay: 0,
        easing: "easeInOutCirc(1, .5)",
        // easing: "spring(1, 80, 10, 0)",
      })
    }, 600)
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("resize", this.calculateSize)
    }
  }

  calculateSize = () => {
    if (typeof window !== `undefined`) {
      this.setState({
        width: window.innerWidth,
      })
      if (window.innerWidth >= 1024) {
        Scrollbar.use(OverscrollPlugin)
        Scrollbar.init(document.querySelector(`.${styles.scrollbar}`), {
          // alwaysShowTracks: true,
          damping: 0.15,
          plugins: {
            overscroll: {},
          },
        })
      } else {
        Scrollbar.destroy(document.querySelector(`.${styles.scrollbar}`))
      }
    }
  }

  getImagePosition = () => {
    const positions = [0, 280, 140]
    if (typeof window !== `undefined`) {
      if (window.innerWidth >= 1024) {
        return positions[Math.floor(Math.random() * positions.length)]
      } else {
        return 0
      }
    }
  }
  render() {
    const { width } = this.state
    let timeout = 200
    return (
      <div className={styles.scrollbar}>
        <div className={["wrapper", styles.wrapper].join(" ")}>
          <header className={styles.header}>
            <div className={styles.logo}>
              {width < 1024 ? <LogoKeytoeSmall /> : <LogoKeytoe />}
            </div>
          </header>
          <nav className={styles.navigation}>
            <StaticQuery
              query={navigationQuery}
              render={data =>
                data.items.edges.map((item, key) => (
                  <NavigationItem
                    key={key}
                    details={{
                      show: true,
                      delay: 100 * key + timeout,
                      imagePosition: this.getImagePosition(),
                      link: item.node.slug,
                      image: item.node.image,
                      title: item.node.title,
                      pre: item.node.pre,
                      color: item.node.color,
                    }}
                  />
                ))
              }
            />
          </nav>
        </div>
        <Footer />
      </div>
    )
  }
}

const navigationQuery = graphql`
  query Navigation {
    items: allWordpressNavigation(sort: {fields: wordpress_id}) {
      edges {
        node {
          title
          slug
          pre
          wordpress_id
          color
          image {
            title
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default HomeNavigation
